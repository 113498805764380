import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from "@angular/common/http";
import { AbaqusApiKeyInterceptor } from "@shared/interceptors/abaqus-api-key.interceptor";
import { IqarusApiKeyInterceptor } from "@shared/interceptors/iqarus-api-key.interceptor";

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AbaqusApiKeyInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: IqarusApiKeyInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken',
        })),
    ] })
export class AppModule {
}
